import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

import { Typography, TypographyLink } from "@start2impact/design2impact";
import { checkCookie, redirectByRole } from "@start2impact/helpers2impact";

import LoginForm from "./login-form.tsx";

import config from "config";


const Login = () => {
	const cookie = checkCookie(config.cookieName);

	useEffect(() => {
		if (cookie) {
			const now = new Date();
			const expiredDate = now.getTime() + cookie.exp;
			const isExpired = expiredDate < now.getTime();
			isExpired ? Cookies.remove(config.cookieName) : redirectByRole(config.cookieName, config.adminUrl, config.talentUrl, config.coachUrl);
		}
	}, [cookie]);

	return (
		<>
			<Helmet>
				<title>Login | start2impact</title>
			</Helmet>

			<main className="min-h-screen bg-ui-secondary px-4 py-14 md:py-36 lg:py-20">
				<div className="flex flex-col md:mx-auto md:max-w-[500px]">
					<img src="https://res.cloudinary.com/start2impact/image/upload/h_64/logo/Logo_University-12_wa6eoi.png" alt="" height="64" width="64" className="self-center" />
					<Typography variant="h3" className="mt-6 md:text-center">Accedi a start2impact</Typography>
					<LoginForm />
					<TypographyLink to={{ pathname: config.signupPath }} isInternal variant="link" className="mt-5 text-center">
						Non hai un account? REGISTRATI ORA
					</TypographyLink>
				</div>
			</main>

		</>
	);
};

export default Login;
