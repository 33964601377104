import { http } from "@start2impact/helpers2impact";

import config from "../../config";

interface RefistrationPlanInfoResponse {
	id: string
	registration_title: string
	slug: string
}


export const fetchRegistrationPlanInfo = async (plan_slug: string | null, recaptcha: string): Promise<RefistrationPlanInfoResponse> => {
	const hasSlug = plan_slug ? `?plan_slug=${plan_slug}` : "";
	const response = await http(config.cookieName, config.loginPath, false)
		.get(`${config.apiUrl}/public/users/registration${hasSlug}`, { headers: { "X-Recaptcha-Token": recaptcha } });

	return response.data.entities.plan.item;
};

export const fetchTopics = async () => {
	const response = await http(config.cookieName, config.loginPath, false).get(`${config.apiUrl}/public/topics`);

	return response.data;
};
