import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useCustomerly } from "react-live-chat-customerly";

import { Button, Footer, Typography } from "@start2impact/design2impact";

import config from "config";


const Error404 = () => {
	const { open } = useCustomerly();
	useEffect(() => {
		const body = document.querySelector(".body-with-sidebar");
		if (!body) return;

		body.classList.remove("body-with-sidebar");
	}, []);


	return (
		<>
			<Helmet>
				<title>Pagina non trovata | start2impact</title>
			</Helmet>

			<div
				className="flex min-h-screen flex-col items-center justify-center bg-brand-primary-light text-center"
			>
				<img src={`${config.assetsPath}images/404.svg`} alt="404" className="block h-auto max-w-full" />
				<Typography variant="h1" className="sr-only size-0">404 - Pagina non disponibile</Typography>

				<Typography variant="h1" className="mt-14 text-brand-mid">Ops! Pagina non disponibile</Typography>
				<Typography variant="label-1" className="mx-0 mb-0 mt-3 text-greyscale-mid">Scrivi in chat cosa stavi cercando e ti aiuteremo.</Typography>

				<Button as="internal-link" className="mt-8" to={{ pathname: config.basepath }}>Torna alla home</Button>
			</div>

			<Footer openCustomerlyLiveChat={config.isProduction ? () => open() : undefined} />
		</>
	);
};

export default Error404;
