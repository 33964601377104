import * as Sentry from "@sentry/react";

const initSentry = () => {
	Sentry.init({
		dsn: "https://778925481a4fea8cf40255e80578293e@o4507804125560832.ingest.de.sentry.io/4507848174272592",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};

export default initSentry;
