import { useEffect } from "react";
import Cookies from "js-cookie";

import { checkCookie } from "@start2impact/helpers2impact";

import config from "config";


const Logout = () => {
	useEffect(() => {
		const cookie = checkCookie(config.cookieName);
		const isImpersonating = cookie ? cookie.user_was?.email : false;

		if (!cookie) {
			window.location.href = config.loginPath;
		}

		else if (isImpersonating) {
			window.location.href = `${config.talentUrl}/admin/users/stop_impersonating`;
		}

		else {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.remove(config.cookieName, { domain });
			window.location.href = config.loginPath;
		}
	}, []);

	return <div />;
};

export default Logout;
