import * as yup from "yup";


export const recoveryPswValidator = yup.object({
	email: yup
		.string()
		.trim()
		.email("Inserisci un indirizzo email valido")
		.required("Il campo 'email' è obbligatorio"),
});
