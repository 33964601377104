import { AiOutlineHome, AiOutlineTrophy, AiOutlineUser } from "react-icons/ai";
import { BiBookBookmark, BiUserPin } from "react-icons/bi";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { MdWorkOutline } from "react-icons/md";


const config = {
	isProduction: import.meta.env.PROD,
	apiUrl: import.meta.env.VITE_APP_API_URL,
	notificationsApiUrl: import.meta.env.VITE_APP_NOTIFICATION_URL,
	studentApiUrl: import.meta.env.VITE_APP_AUTHENTICATION_URL,
	badgeApiUrl: import.meta.env.VITE_APP_BADGE_URL,
	reCaptchaKey: import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY!,
	appUrl: import.meta.env.VITE_APP_URL,
	talentUrl: import.meta.env.VITE_APP_TALENT_URL,
	adminUrl: import.meta.env.VITE_APP_ADMIN_URL,
	coachUrl: import.meta.env.VITE_APP_COACH_URL,
	assetsPath: import.meta.env.VITE_BASE_URL,
	cookieName: "iub_pref",
	loginPath: "/login",
	logoutPath: "/logout",
	basepath: "/",
	onboardingPath: "/onboarding/master",
	signupPath: "/signup",
	cloudinaryFetchPath: "https://res.cloudinary.com/start2impact/image/fetch/",
	cookieExpirationDays: 400,
	profilePath: "/profile",
	menuItems: (plan = "m1-o18") => (plan === "m1-o26" // plan===piano orientamento
		? [
			{
				id: 1,
				name: "Home",
				title: "Home | start2impact",
				to: "/",
				active: true,
				isInternal: false,
				icon: AiOutlineHome,
			},
			{
				id: 2,
				name: "Career Counseling",
				title: "Corso Career Counseling | start2impact",
				to: "/corsi/career-counseling",
				active: false,
				isInternal: false,
				icon: MdWorkOutline,
			},
			{
				id: 3,
				name: "Account",
				to: "/subscribe/index",
				active: false,
				isInternal: false,
				icon: AiOutlineUser,
			},
		]
		: [
			{
				id: 1,
				name: "Home",
				title: "Home | start2impact",
				to: "/",
				active: true,
				isInternal: false,
				icon: AiOutlineHome,
			},
			{
				id: 2,
				name: "Master",
				title: "Scegli il tuo Master | start2impact",
				to: "/master",
				active: false,
				isInternal: false,
				icon: HiOutlineAcademicCap,
			},
			{
				id: 3,
				name: "Corsi",
				title: "Tutti i Corsi | start2impact",
				to: "/corsi",
				active: false,
				isInternal: false,
				icon: BiBookBookmark,
			},
			{
				id: 4,
				name: "Classifica",
				title: "Classifica | start2impact",
				to: "/classifica",
				active: false,
				isInternal: false,
				icon: AiOutlineTrophy,
			},
			{
				id: 5,
				name: "Community",
				title: "Community | start2impact",
				to: "/community",
				active: false,
				isInternal: false,
				icon: BiUserPin,
			},
			{ id: 6, name: "Career Service", to: "/topics/mindset/guides/career-service", active: false, isInternal: false, icon: MdWorkOutline },
			{ id: 7, name: "Account", to: "/subscribe/index", active: false, isInternal: false, icon: AiOutlineUser },
		]),
};


export default config;
