import * as yup from "yup";


export const loginValidators = yup.object({
	email: yup
		.string()
		.trim()
		.email("Inserisci un indirizzo email valido")
		.required("Il campo 'email' è obbligatorio"),
	password: yup
		.string().trim()
		.min(4, "Il campo 'password' è obbligatorio")
		.required("Il campo 'password' è obbligatorio"),
});
