import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import { useToast } from "@start2impact/design2impact";
import { redirectByRole } from "@start2impact/helpers2impact";

import { loginValidators } from "../validators";

import { login } from "api/mutations";

import config from "config";


interface FormInitialValues {
	email: string;
	password: string;
}


const formInitialValues: FormInitialValues = { email: "", password: "" };

const useLoginForm = () => {
	const { showToast } = useToast();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		isPending,
		mutate: loginFn,
	} = useMutation({
		mutationFn: login,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.token, { expires: config.cookieExpirationDays, domain });
			redirectByRole(config.cookieName, config.adminUrl, config.talentUrl, config.coachUrl);
		},
		onError: () => showToast("Credenziali Email o password non valide.", "error"),
	});

	const handleLogin = async (data: FormInitialValues) => {
		if (!executeRecaptcha) {
			showToast("Impossibile Accedere", "error");
			return;
		}

		const recaptcha = await executeRecaptcha("login");
		const referrersId = Cookies.get("referrers");

		const loginData = { ...data, guest_identifier: referrersId || null, recaptcha };
		loginFn(loginData);
	};

	const formik = useFormik({
		initialValues: formInitialValues,
		onSubmit: (data) => {
			handleLogin(data);
		},
		validationSchema: loginValidators,
	});

	return { formik, isPending };
};

export default useLoginForm;
